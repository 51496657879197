<template>
  <div>
    <Component :is="resolvedComponent" v-bind="attributes"></Component>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from "vue";
import {
  resolveComponent as vResolveComponent,
  computed as vComputed,
} from "vue";
import { Button } from "~/components/StrapiButton/types";

type bannerAttributes = {
  createdAt: string;
  updatedAt: string;
  description: string;
  publishedAt: string;
  subtitle?: string;
  title: string;
  type: string;
  buttons: Button[];
};

const props = defineProps({
  id: { type: Number, required: true },
  attributes: {
    type: Object as PropType<bannerAttributes>,
    default: () => ({}),
  },
});

const resolvedComponent = vComputed(() =>
  vResolveComponent(`Banner${props.attributes?.type?.toString()}`)
);
</script>
